import { defineStore } from "pinia";

import type { TableHeader, TableRow } from "@/types/table";
import { checkGranularityForTable } from "@/utils/formatters.ts";
import type { RequestError, requestParams } from "@/types/requests";
import type { AdsSummaryCampanyDetailsStore } from "@/types/adsSummary/campanyDetails";
import { replaceSingleController } from "@/utils/createAbortControllers";
import {
  getCampaignTargetingTable as getCampaignTargetingTableApi,
  getCampaignTypesTable as getCampaignTypesTableApi,
  getProductCategoriesTable as getProductCategoriesTableApi,
} from "@/api/adsSummary";

let tableTypesController = new AbortController();
let tableTargetController = new AbortController();
let tableCategoriesController = new AbortController();

export const useCampaignDetailsStore = defineStore("campaignDetails", {
  state: (): AdsSummaryCampanyDetailsStore => ({
    campaignTypes: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    campaignTargeting: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    productCategories: {
      pagination: {
        totalPages: 1,
        currentPage: 1,
        pageSizeNumber: 10,
      },
      data: [],
    },
    isCampaignTypesLoader: true,
    isCampaignTypesTotalLoader: true,
    isCampaignTargetingLoader: true,
    isCampaignTargetingTotalLoader: true,
    isProductCategoriesLoader: true,
    isProductCategoriesTotalLoader: true,
  }),

  actions: {
    abortTableTypeRequest() {
      tableTypesController = replaceSingleController(tableTypesController);
    },
    abortTableTargetRequest() {
      tableTargetController = replaceSingleController(tableTargetController);
    },
    abortTableCategoriesRequest() {
      tableCategoriesController = replaceSingleController(
        tableCategoriesController,
      );
    },

    getCampaignTypesTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers?: TableHeader[],
        custom?: boolean,
      ) => void,
      granularity?: "MONTH" | "WEEK" | "DAY",
    ) {
      if (params.limit === 0) {
        this.isCampaignTypesTotalLoader = true;
      } else {
        this.isCampaignTypesLoader = true;
      }
      getCampaignTypesTableApi(params, {
        signal: tableTypesController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback &&
              callback(
                data.data,
                [
                  {
                    name: checkGranularityForTable(granularity),
                    value: "DATE",
                  },
                  {
                    name: "Campaign Type",
                    value: "CAMPAIGN_TYPE",
                    sortable: false,
                  },
                  {
                    name: "Ad Revenue",
                    value: "REVENUE",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Revenue Change %",
                    value: "REVENUE_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Spend",
                    value: "SPEND",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Spend Change %",
                    value: "SPEND_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Conversion",
                    value: "CONVERSION",
                    isPercentage: true,
                    roundedWithTwoDecimalsAndMultiply: true,
                  },
                  {
                    name: "Ad Conversion Change %",
                    value: "CONVERSION_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                ],
                true,
              );
          } else {
            this.campaignTypes.data = data.data;
            this.campaignTypes.pagination = data.pagination;
          }
          this.isCampaignTypesLoader = false;
          this.isCampaignTypesTotalLoader = false;
        })
        .catch((err: RequestError) => {
          const errorData = JSON.parse(err?.message);
          this.isCampaignTypesLoader = false;
          this.isCampaignTypesTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isCampaignTypesLoader = true;
          }
        });
    },
    getCampaignTargetingTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers?: TableHeader[],
        custom?: boolean,
      ) => void,
      granularity?: "MONTH" | "WEEK" | "DAY",
    ) {
      if (params.limit === 0) {
        this.isCampaignTargetingTotalLoader = true;
      } else {
        this.isCampaignTargetingLoader = true;
      }
      getCampaignTargetingTableApi(params, {
        signal: tableTargetController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback &&
              callback(
                data.data,
                [
                  {
                    name: checkGranularityForTable(granularity),
                    value: "DATE",
                  },
                  {
                    name: "Campaign Targeting",
                    value: "CAMPAIGN_TARGETING",
                    isPercentage: false,
                    sortable: false,
                  },
                  {
                    name: "Ad Revenue",
                    value: "REVENUE",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Revenue Change %",
                    value: "REVENUE_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Spend",
                    value: "SPEND",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Spend Change %",
                    value: "SPEND_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Conversion",
                    value: "CONVERSION",
                    isPercentage: true,
                    roundedWithTwoDecimalsAndMultiply: true,
                  },
                  {
                    name: "Ad Conversion Change %",
                    value: "CONVERSION_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                ],
                true,
              );
          } else {
            this.campaignTargeting.data = data.data;
            this.campaignTargeting.pagination = data.pagination;
          }
          this.isCampaignTargetingLoader = false;
          this.isCampaignTargetingTotalLoader = false;
        })
        .catch((err: RequestError) => {
          const errorData = JSON.parse(err?.message);
          this.isCampaignTargetingLoader = false;
          this.isCampaignTargetingTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isCampaignTargetingLoader = true;
          }
        });
    },
    getProductCategoriesTable(
      params: requestParams,
      callback?: (
        data: TableRow[],
        headers?: TableHeader[],
        custom?: boolean,
      ) => void,
      granularity?: "MONTH" | "WEEK" | "DAY",
    ) {
      if (params.limit === 0) {
        this.isProductCategoriesTotalLoader = true;
      } else {
        this.isProductCategoriesLoader = true;
      }
      getProductCategoriesTableApi(params, {
        signal: tableCategoriesController.signal,
      })
        .then(({ data }) => {
          if (params.limit === 0) {
            callback &&
              callback(
                data.data,
                [
                  {
                    name: checkGranularityForTable(granularity),
                    value: "DATE",
                  },
                  { name: "Product Category", value: "PRODUCT_CATEGORY" },
                  {
                    name: "Product Title",
                    value: "PRODUCT_TITLE",
                    maxLength: 20,
                  },
                  {
                    name: "Ad Revenue",
                    value: "REVENUE",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Revenue Change %",
                    value: "REVENUE_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Spend",
                    value: "SPEND",
                    currency: true,
                    isNumber: true,
                  },
                  {
                    name: "Ad Spend Change %",
                    value: "SPEND_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                  {
                    name: "Ad Conversion",
                    value: "CONVERSION",
                    isPercentage: true,
                    roundedWithTwoDecimalsAndMultiply: true,
                  },
                  {
                    name: "Ad Conversion Change %",
                    value: "CONVERSION_DELTA",
                    isPercentage: true,
                    roundedWithZeroDecimals: true,
                    dividedForXlsx: true,
                  },
                ],
                true,
              );
          } else {
            this.productCategories.data = data.data;
            this.productCategories.pagination = data.pagination;
          }
          this.isProductCategoriesLoader = false;
          this.isProductCategoriesTotalLoader = false;
        })
        .catch((err: RequestError) => {
          const errorData = JSON.parse(err?.message);
          this.isProductCategoriesLoader = false;
          this.isProductCategoriesTotalLoader = false;
          if (errorData.status === "canceled") {
            this.isProductCategoriesLoader = true;
          }
        });
    },

    cleanStore() {
      this.campaignTypes = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.campaignTargeting = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.productCategories = {
        pagination: {
          totalPages: 1,
          currentPage: 1,
          pageSizeNumber: 10,
        },
        data: [],
      };
      this.isCampaignTypesLoader = true;
      this.isCampaignTypesTotalLoader = true;
      this.isCampaignTargetingLoader = true;
      this.isCampaignTargetingTotalLoader = true;
      this.isProductCategoriesLoader = true;
      this.isProductCategoriesTotalLoader = true;
    },
  },
});
