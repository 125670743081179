<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from "vue";

import CommonMultiSelect from "@/components/common/CommonMultiSelect.vue";

const props = defineProps<{
  resetValue: string[];
}>();
const emits = defineEmits(["valueUpdate"]);

const optionsList = computed<Record<string, string | string[]>[]>(() => [
  { value: [], name: "All" },
  { value: "AMS", name: "AMS" },
  { value: "DSP", name: "DSP" },
  { value: "PROMO", name: "PROMO" },
]);

const chooseValues = ref<Record<string, string | string[]>[]>([
  optionsList.value[0],
]);

const defineValues = () => {
  const newValues = chooseValues.value?.flatMap(
    (el: Record<string, string | string[]>) => el.value,
  );
  emits("valueUpdate", newValues);
};

const onSelect = (value: Record<string, string>) => {
  if (value.name?.toLowerCase() === "all") {
    chooseValues.value = [];
  } else {
    chooseValues.value.find(
      (el: Record<string, string | string[]>) =>
        (el.name as string)?.toLowerCase() === "all",
    ) && chooseValues.value.splice(0, 1);
  }
};

const selectedTags = ({ values }: { values: Record<string, string>[] }) => {
  return values.map((el) => el.name);
};

watch(
  () => props.resetValue,
  (newValue) => {
    if (newValue.length === 0) {
      chooseValues.value = [optionsList.value[0]];
    }
  },
);

onUnmounted(() => {
  chooseValues.value = [optionsList.value[0]];
});
</script>

<template>
  <div class="flex flex-col min-w-[300px]">
    <div>
      <CommonMultiSelect
        v-model="chooseValues"
        :options="optionsList"
        label="name"
        :show-labels="false"
        @select="onSelect"
        @close="defineValues"
        :multiple="true"
        :clear-on-select="false"
        :close-on-select="false"
        track-by="name"
        placeholder=""
        :allowEmpty="false"
        :searchable="false"
      >
        <template v-slot:selection="selection">
          <div class="flex w-full align-center min-h-[26px]">
            <div
              v-for="(item, index) in selectedTags(selection)"
              :key="index"
              class="flex justify-center items-center mr-1 py-1 px-2 rounded-full text-mainText text-[14px] font-normal border-[1px] border-gray-200 dark:border-secondaryText"
              :class="{ 'border-none hidden': index >= 2 }"
            >
              <div
                class="leading-none max-w-full flex-initial cursor-pointer text-mainText"
              >
                {{ item }}
              </div>
            </div>
            <div
              v-if="selectedTags(selection).length > 2"
              class="flex items-center ml-auto text-mainText"
            >
              +{{ selectedTags(selection).length - 2 }} More
            </div>
          </div>
        </template>
        <template v-slot:option="data">
          <div
            class="flex w-full justify-between items-center text-[14px] font-medium"
          >
            {{ data.option.name }}
          </div>
        </template>
      </CommonMultiSelect>
    </div>
  </div>
</template>
