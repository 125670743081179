import { DateTime } from "luxon";

import type { Channels } from "@/store/main";
import type { requestParams } from "@/types/requests";
import type { TableRow, TableHeader } from "@/types/table";

export const formatCurrency = (
  amount: number,
  isNegativeSymbolBeforeCurrency: boolean = true,
  showDecimals: boolean = false,
) => {
  const sign = amount < 0 ? "-" : "";
  const absAmount = Math.abs(amount);

  const formattedAmount = showDecimals
    ? absAmount.toFixed(2)
    : absAmount.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

  return (
    (isNegativeSymbolBeforeCurrency && sign ? `${sign}$` : "$") +
    formattedAmount +
    (!isNegativeSymbolBeforeCurrency && sign ? `${sign}$` : "")
  );
};

export const formatComma = (amount: number) => {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatNumber = (amount: number) => {
  if (amount >= 1000 && amount < 10000) {
    return (
      (amount / 1000).toLocaleString("en", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }) + "k"
    );
  } else if (amount >= 10000) {
    return Math.round(amount / 1000).toLocaleString("en") + "k";
  } else {
    return amount.toLocaleString("en");
  }
};

export const formatOldFormatOfDateToAmericanFormat = (date: string) => {
  const formattedDate = DateTime.fromISO(date).toFormat("LL/dd/yyyy");

  return formattedDate;
};

// Formatting row values for table
export const formattingValue = (
  data: string | number,
  row: TableRow,
  col: TableHeader,
  isShouldTruncate = true,
) => {
  if (data === null) {
    return "";
  }

  // Handle capitalizing first letters
  if (col.capitalizeFirstLetterOfEachWord) {
    return capitalizeFirstLetterOfEachWord(data as string);
  }

  // Currency formatting
  if (col.currency) {
    return formatCurrency(data as number);
  }

  // Handle rounding and multiplication
  if (col.roundedWithTwoDecimalsAndMultiply) {
    return ((data as number) * 100).toFixed(2);
  }
  if (col.roundedToTwoDecimals) {
    return (data as number).toFixed(2);
  }
  if (col.roundedWithZeroDecimalsAndMultiply) {
    return ((data as number) * 100).toFixed(0);
  }
  if (col.roundedWithZeroDecimals) {
    return Number(data).toFixed(0);
  }

  // Handle comma formatting
  if (col.addComma) {
    return formatComma(data as number);
  }

  if (col.commasPlusDecimals) {
    return (+(data as number).toFixed(2)).toLocaleString();
  }

  // Date formatting
  if (col.value === "DATE" || col.date) {
    if (!row[col.value]) return "Selected period";
    return formatOldFormatOfDateToAmericanFormat(
      row[col.value] || row["DATE"] || "Selected period",
    );
  }

  // Truncate string
  if (!!col.maxLength && isShouldTruncate) {
    return truncateString(data as string, col.maxLength);
  }

  // Null to empty string
  if (col.nullToEmptyString) {
    return data ?? "";
  }

  // Currency with two decimals
  if (col.currencyWithTwoDecimals) {
    return `$${(data as number).toFixed(2)}`;
  }

  return data;
};

export const truncateString = (str: string, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.slice(0, maxLength - 1) + "...";
  }
  return str;
};

export const checkToParams = (params: requestParams) => {
  let findedParams = "";
  let isFirstParam = true;

  for (const key of Object.keys(params)) {
    if (params[key as keyof requestParams] !== "") {
      if (isFirstParam) {
        findedParams += `?${key}=${params[key as keyof requestParams]}`;
        isFirstParam = false;
      } else {
        findedParams += `&${key}=${params[key as keyof requestParams]}`;
      }
    }
  }

  return findedParams;
};

export const capitalizeFirstLetterOfEachWord = (string: string) => {
  return string
    .replace(/[_-]/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const renameChannels = (channels: Channels[], channel: number) => {
  return channels.find((item) => item.CHANNEL_ID === channel)?.NAME;
};

export const checkGranularityForTable = (
  date: "MONTH" | "WEEK" | "DAY" | undefined,
) => {
  if (date === "WEEK") {
    return "Week Start";
  }

  if (date === "DAY") {
    return "Date";
  }

  return "Month Start";
};
